import {
  useEditor,
  EditorContent,
  FloatingMenu,
  BubbleMenu,
  Editor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import * as Styled from './Styles';
import { HocuspocusProvider } from '@hocuspocus/provider';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import ColabCursor from './ColabCursor.ts';
import Collaboration from '@tiptap/extension-collaboration';
import Highlight from '@tiptap/extension-highlight';
import * as Y from 'yjs';
import { MouseEvent, RefObject, useEffect, useState } from 'react';
import { ContractHTML } from './HTML.ts';
import Icon, { IconNames } from '../../assets/icons/Icons.tsx';
import { useContract } from '../../contexts/ContractContext.tsx';
import { Selection } from '@tiptap/pm/state';
import { InputFloatingMessageBox } from '../InputFloatingMessageBox/InputFloatingMessageBox.tsx';

interface Props {
  contractBox?: RefObject<{}>;
  contractID?: string;
  data?: { name?: string; role?: string; salary?: string };
}

// collaborative editor
export default function CollaborativeEditor(props: Props) {
  const { contractBox, contractID, data } = props;
  const { handleSetEditor, handleSelectionChange } = useContract();
  const [doc, setDoc] = useState<Y.Doc>();
  const [provider, setProvider] = useState<HocuspocusProvider>();

  // Set up Liveblocks Yjs provider
  useEffect(() => {
    const yDoc = new Y.Doc();
    const yProvider = new HocuspocusProvider({
      url: 'wss://www.typeupapi.net/ws', //34.170.118.106/ws replace with type up
      name: contractID,
      document: yDoc,
      token: 'gp-ipe',
    });
    setDoc(yDoc);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.destroy();
    };
  }, [contractID, doc, provider]);

  if (!doc || !provider) {
    return null;
  }

  return (
    <ColabEditor
      doc={doc}
      provider={provider}
      onCreate={(editor) => handleSetEditor(editor)}
      contractBox={contractBox}
      data={data}
      onSelectionChange={(selection: Selection) =>
        handleSelectionChange(selection)
      }
    />
  );
}

interface InputInfo {
  position?: { left: number | string; top: number | string };
  selection?: { text?: string; from: number; to: number };
  author?: string;
}

interface EditorProps {
  doc: Y.Doc;
  provider: HocuspocusProvider;
  onCreate: (editor: any) => void;
  onSelectionChange: (selection: Selection) => void;
  contractBox?: RefObject<{}>;
  data?: { name?: string; role?: string; salary?: string };
}

const ColabEditor = (props: EditorProps) => {
  const { doc, provider, onCreate, onSelectionChange, contractBox, data } =
    props;
  const { handleShowCommentPopUp, handleSetInputInfo } = useContract();
  const content = ContractHTML(data?.name, data?.salary, data?.role);
  // define your extension and conttent
  const extensions = [
    Highlight,
    StarterKit.configure({
      bulletList: { keepMarks: true, keepAttributes: false },
      orderedList: { keepMarks: true, keepAttributes: false },
      // Note: The collaboration extension implements its own history handling
      history: false,
    }),
    Collaboration.configure({ document: doc }),
    CollaborationCursor.configure({
      provider: provider,
      user: { color: '#A7B3BE' }, //note: only supportts hex colors in full
      render: (user) => ColabCursor('Nicole'), // implements our own custom corsor
    }),
  ];

  const editor = useEditor({
    extensions,
    content,
    editorProps: {
      handleKeyDown(view, event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          return true;
        }
      },
      handleTextInput(view, from, to, text) {
        return true;
      },
    },
    onSelectionUpdate({ editor, transaction }) {
      onSelectionChange(editor.view.state.selection);
      //console.log(transaction);
    },
    onCreate: ({ editor }) => {
      onCreate(editor);
    },
    editable: true,
  });

  const handleCommentClick = (e: MouseEvent) => {
    //get items we need
    const selection = editor.view.state.selection;
    const state = editor.view.state;
    const { from, to } = selection;
    const text = state.doc.textBetween(from, to, ' ');

    // set info for the comment
    const info = {
      position: { left: e.clientX, top: e.clientY },
      selection: { text, from, to },
      author: 'Camila Martinez',
    };
    handleSetInputInfo(info);
    handleShowCommentPopUp(true);
    // hilight the selected resion and retiurn  focus to editor
    editor.commands.toggleHighlight();
    editor.commands.focus(to);
  };

  return (
    <Styled.Editor editor={editor}>
      <Styled.CommentMenu updateDelay={1000} editor={editor}>
        <div onClick={(e: MouseEvent) => handleCommentClick(e)}>
          <Icon
            name={IconNames.plusCircle}
            fill={'#FFFF00'}
            theme={'white'}
            size={28}
            stroke={1.5}
            pointer={true}
          />
        </div>
      </Styled.CommentMenu>
      <InputFloatingMessageBox constraints={contractBox} />
    </Styled.Editor>
  );
};
